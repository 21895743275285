import { Controller } from '@hotwired/stimulus'
import { VISIBILITY_STATUS_BETWEEN_DATES } from '../../../../../constants/admins/advertisement'

export default class extends Controller {
  static targets = ["visibilityStatusSelect", "datetimepickersWrapper"]

  connect () {
    const visibilityStatus = parseInt(this.data.get("visibilityStatus"))

    if (visibilityStatus !== VISIBILITY_STATUS_BETWEEN_DATES) this.hideDatetimepickers()

    this.visibilityStatusSelectTarget.onchange = this.onVisibilityStatusChange.bind(this)
  }

  onVisibilityStatusChange(ev) {
    if (ev.target.value == VISIBILITY_STATUS_BETWEEN_DATES) {
      this.showDatetimepickers()
    } else {
      this.hideDatetimepickers()
    }
  }

  showDatetimepickers () {
    this.datetimepickersWrapperTarget.style.display = "block"
  }

  hideDatetimepickers () {
    this.datetimepickersWrapperTarget.style.display = "none"
  }
}
