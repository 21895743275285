import * as Routes from 'routes.js.erb'

$(document).on('turbo:load', () => {
  $("#page_spinner").hide();
  if ($('#events-event-session').length !== 0) {
    const sessionMaxTickets = $(document).find('.session_max_tickets').val()
    let block = false
    const layoutId = new URLSearchParams(window.location.search).get('layout_id')
    const calculateTotals = (precart = {}) => {
      let totalTickets = 0
      let totalPrice = 0
      if (layoutId) {
        precart.groups.map(group => {
          totalTickets += group.num
          totalPrice += group.num * parseFloat(group.price)
        })
        totalTickets += precart.seats.length
        totalPrice += precart.seats.reduce((accumulator, object) => {
          return accumulator + parseFloat(object.price)
        }, 0)
      } else {
        totalTickets = $('.ticket').length
        $('.ticket').each(a => totalPrice + 10)

        $('.ticket_count').each(function () {
          const ticketCount = parseFloat($(this).text())
          if (sessionMaxTickets) {
            // the == needs to be 2 = not 3 because is checking if number match the number in string
            if ((totalTickets + ticketCount) == (sessionMaxTickets)) {
              block = true
              $(document).find('.increment').removeClass('bg-dark-gray').addClass('bg-light-gray')
              $(document).find('.tooltip').attr('data-active', 'true')
            } else if ((totalTickets + ticketCount) > (sessionMaxTickets)) {
              return
            }
          }
          const ticketPrice = $(this).parents('.render_typology').find('.ticket_price').text()
          totalTickets += ticketCount
          totalPrice += parseFloat(ticketPrice.replace(',', '.')).toFixed(2) * ticketCount
        })
      }
      const $button = $('.event_session_bottom__submit')
      $('.total_tickets').text(totalTickets)

      $('.total_price').text(totalPrice.toFixed(2).replace('.', ','))

      if (totalTickets >= 1) {
        $button.prop('disabled', false)
      } else {
        $button.prop('disabled', true)
      }
    }

    $('.event_session_bottom__submit').on('click', () => {
      $("#page_spinner").show()
    })

    $('.increment').on('click', function () {
      const count = $(this).parent().find('.ticket_count')
      const hiddenCount = $(this).parent().find('.hidden_count')
      const availability = $(this).parent().find('.hidden_availability')
      const $button = $('.event_session_bottom__submit')
      const typologyMaxTickets = $(this).parent().find('.typology_max_tickets').val()
      const newValue = (parseInt(count.text()) + 1)
      if (availability.val() === 'true' && block === false) {
        if (typologyMaxTickets) {
          if (newValue > typologyMaxTickets) {
            return
          } else if (newValue == typologyMaxTickets) {
            $(this).parent().find('.increment').removeClass('bg-dark-gray').addClass('bg-light-gray')
            $(this).parent().find('.tooltip').attr('data-active', 'true')
          }
        }
        count.text(`${parseInt(count.text()) + 1}`)
        hiddenCount.val(count.text())

        if (count.text() === '1') {
          $(this).parent().find('.decrement').removeClass('bg-light-gray').addClass('bg-dark-gray')
          $button.prop('disabled', false)
        }

        calculateTotals()
      }
    })

    $('.decrement').on('click', function () {
      const count = $(this).parent().find('.ticket_count')
      const hiddenCount = $(this).parent().find('.hidden_count')

      if (parseInt(count.text()) > 0) {
        count.text(`${parseInt(count.text()) - 1}`)
        hiddenCount.val(count.text())
        const $button = $('.event_session_bottom__submit')

        if (count.text() === '0') {
          $(this).removeClass('bg-dark-gray').addClass('bg-light-gray')
          $button.prop('disabled', true)
        }

        if ($(this).parent().find('.increment').hasClass('bg-dark-gray')) {
          $(this).parent().find('.increment').removeClass('bg-dark-gray').addClass('bg-dark-gray')
          $(this).parent().find('.tooltip').attr('data-active', 'false')
        }
        if (block) {
          block = false
          $('.ticket_count').each(function () {
            const availability = $(this).parent().find('.hidden_availability')
            if (availability.val() === 'true') {
              $(this).parent().find('.increment').removeClass('bg-light-gray').addClass('bg-dark-gray')
              $(document).find('.tooltip').attr('data-active', 'false')
            }
          })
        }

        calculateTotals()
      }
    })

    if (layoutId) {
      const containerEl = document.querySelector('#layout_widget')
      const seats = $('#selected_tickets').val() ? $('#selected_tickets').val().split(',') : []
      let groups = $('#selected_groups').val() ? $('#selected_groups').val() : []
      groups = JSON.parse(groups)
      const settings = {
        initialSelection: {
          seats: seats.map(s => ({ id: s })),
          groups: groups.map(s => ({ id: s.zone_id, num: s.num }))
        },
        showSelectedView: true,
        showListView: true,
        showColorLegend: true,
        groupClickBehaviour: 'selectAndExpand',
        highlightSelectedGroups: false,
        disableZoom: true,
        minimap: false,
        tooltip: true,

        onChangeSelection: (selected) => {
          $('#group_ids').val(selected.groups)
          $('#seat_ids').val(selected.seats.map((a) => a.id))
          calculateTotals(selected)
        },

        onClickSeat: (seat) => {
          if ($('#seat_ids').val().includes(seat.id)) {
            addToCart(seat, 'seat')
          } else {
            removeFromCart(seat.id, 'seat')
          }
        },
        onClickAdmissionTicket: (group, operation) => {
          if (operation == 'remove') {
            removeFromCart(group.id, 'admission')
          } else if (operation == 'remove-all') {
            removeAllFromCart(group.id)
          } else {
            addToCart(group, 'admission')
          }
        },
        widgetInitialized: (initialize) => {
          $('#spinner').hide()
        }
      }

      const session_data = {
        event_id: $('#event_id').val(),
        location_id: $('#location_id').val(),
        date: $('#date').val(),
        session: $('#time').val()
      }
      const permission_code = 'TL_WEBSITE'

      $('#widgetScript').on('load', () => {
        initiateWidget('')
      })

      if (window.performance.getEntriesByType('navigation')[0].type == 'reload') {
        initiateWidget('')
      }

      function initiateWidget () {
        const widget = new Widget(layoutId, settings, permission_code, session_data)
        widget.render(containerEl)
        widget.init()
      }
    }

    const addToCart = (object, type) => {
      let data = {
        event_id: $('#event_id').val(),
        location_id: $('#location_id').val(),
        date: $('#date').val(),
        time: $('#time').val(),
        widget_call: true
      }
      if (type == 'seat') {
        data = {
          ...data,
          ...{
            zone_label: object.groupName,
            row_label: object.rowLabel,
            seat_label: object.label,
            seat_ids: object.id,
            zone_id: object.parent_id
          }
        }
      } else {
        data = {
          ...data,
          ...{
            zone_id: object.group.id,
            zone_label: object.group.zoneName,
            num: 1
          }
        }
      }
      $.ajax({
        type: 'POST',
        url: Routes.cart_add_path(),
        data: data,
        success: function (response) {},
        error: function () {
          if (type == 'seat') {
            widget.unselectSeat(object.id)
          } else {
            location.reload()
          }
        }
      })
    }
    const removeFromCart = (objectId, type) => {
      if (type == 'seat') {
        $.ajax({
          type: 'DELETE',
          url: Routes.cart_remove_from_widget_path(objectId),
          success: function (response) {
          },
          error: function (response) {
            widget.selectSeat(objectId)
          }
        })
      } else {
        $.ajax({
          type: 'DELETE',
          url: Routes.cart_remove_from_admission_widget_path(objectId),
          success: function (response) {
          },
          error: function (response) {
            location.reload()
          }
        })
      }
    }
    const removeAllFromCart = (groupId) => {
      $.ajax({
        type: 'DELETE',
        url: Routes.cart_remove_all_from_admission_widget_path(groupId),
        success: function (response) {
        },
        error: function (response) {
          location.reload()
        }
      })
    }
  }
})


