$(document).on('turbo:load', () => {
  if ($('#admin-utms-new').length !== 0 || $('#admin-utms-create').length !== 0) {
    $('#url_button').on('click', () => {
      if ($('#url_button').hasClass('btn-tl--main-border')) {
        $('#url_button').removeClass('btn-tl--main-border').addClass('btn-tl--main')
        $('#event_button').removeClass('btn-tl--main').addClass('btn-tl--main-border')
      }
      $('#url_field').show()
      $('#event_field').val('')
      $('#event_field').hide()
    })

    $('#event_button').on('click', () => {
      if ($('#event_button').hasClass('btn-tl--main-border')) {
        $('#event_button').removeClass('btn-tl--main-border').addClass('btn-tl--main')
        $('#url_button').removeClass('btn-tl--main').addClass('btn-tl--main-border')
      }
      $('#event_field').show()
      $('#url_field').val('')
      $('#url_field').hide()
    })

    $('#generate_utm').on('click', () => {
      const finalUrl = generateUrl()
      $('#generated_utm').get(0).innerText = finalUrl
      $('#utm_final_url').get(0).value = finalUrl
      $('#copy_button').attr('data-components--copy-button-url-value', finalUrl)
    })

    $('#new_utm').on('submit', (ev) => {
      ev.preventDefault()
      const finalUrl = generateUrl()
      $('#utm_final_url').get(0).value = finalUrl
      $('#new_utm').trigger('submit.rails')
    })
  }
  function generateUrl () {
    let url = $('#utm_url').get(0).value
    if (url === '') {
      const event = $('#utm_select_2_url').get(0).value
      url = `${window.location.host}/events/${event}`
      $('#utm_url').get(0).value = url
    }
    const campaignName = $('#utm_campaign_name').get(0).value
    const campaignId = $('#utm_campaign_id').get(0).value
    const campaignSource = $('#utm_campaign_source').get(0).value
    const campaignMedium = $('#utm_campaign_medium').get(0).value

    let finalUrl = ''
    if (url.includes('?')) {
      finalUrl = `${url}&utm_source=${campaignSource}&utm_medium=${campaignMedium}&utm_campaign=${campaignName}&utm_id=${campaignId}`.toLowerCase()
    } else {
      finalUrl = `${url}?utm_source=${campaignSource}&utm_medium=${campaignMedium}&utm_campaign=${campaignName}&utm_id=${campaignId}`.toLowerCase()
    }
    return finalUrl
  }
})
