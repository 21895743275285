import GoogleMapsApiLoader from 'packs/initializers/google_maps_api_loader.js.erb'

$(document).on('turbo:load', () => {
  if ($('#venues-show').length !== 0) {
    /* Google Maps */
    GoogleMapsApiLoader.load().then(() => {
      const googleMapsEl = document.querySelector('[selector="google_map"]')
      const data = googleMapsEl.dataset
      const coords = { lat: parseFloat(data.lat), lng: parseFloat(data.lng) }
      const map = new google.maps.Map(googleMapsEl, { center: coords, zoom: 16 })
      const marker = new google.maps.Marker({ position: coords, map: map, title: data.title })
    })
  }
})
