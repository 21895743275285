$(document).on('turbo:load', () => {
  $('#full_spinner').hide()
  if ($('#cart-index').length !== 0) {
    $('#has_insurance').on('click', ev => {
      $(':checkbox').each(function () {
        this.checked = true
      })
      const $modal = $(ev.target).parents('.insurance-overlay')
      $modal.css('display', 'none')
    })

    $('[selector="close_insurance_modal"]').on('click', ev => {
      const $modal = $(ev.target).parents('.insurance-overlay')
      $modal.css('display', 'none')
    })

    $('#return_to_widget_event').on('click', () => {
      window.top.location.reload()
    })
    $('#finalize_purchase_button').on('click', () => {
      $('#full_spinner').show()
    })

    $('#apply_promo').on('click', (e) => {
      const promo_code = document.getElementById('promo_code').value
      const data = {'promo_code': promo_code} 
      ajax_update_cart('/cart/promo_code', data, campaign_update)
    })

    $('[id=discount_select]').on('change', function (event) {
      const selectedDiscount = event.target.value;
      const ref = this.dataset.ref
      const data = { 'discount_id': selectedDiscount, 'ticket_id': ref }
      ajax_update_cart('/cart/update_ticket_discount', data, discount_update)
    })

    const ajax_update_cart = (url, data, operation) => {
      $('#spinner').css({ visibility: 'visible', opacity: '1' })
      $.ajax({
        url: url,
        type: 'POST',
        data: data,
        success: operation
      });
    }

    const discount_update = (response) => {
      if (response.state.state === 'success') {
        $('#cart_resume').get(0).cartResume.update_discounts(response.cart)
      }
      $('#spinner').css({ visibility: 'hidden', opacity: '0' })
    }

    const campaign_update = (response) => {
      const inputPromoCode = document.getElementById('promo_code')
      const text = document.getElementById('error_text')
      if (response.state.state === 'success') {
        $('#cart_resume').get(0).cartResume.update_campaign(response.cart)
        inputPromoCode.classList.remove("is-invalid")
        text.innerHTML = ""
      } else {
        inputPromoCode.classList.add("is-invalid")
        text.innerHTML = response.message
      }
      $('#spinner').css({ visibility: 'hidden', opacity: '0' })
    }
  }
})
