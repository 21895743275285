import * as Routes from 'routes.js.erb'

$(document).on('turbo:load', () => {
  const gatewayRedirectLinkEl = document.querySelector('[selector="gateway_redirect"]')

  if ($('#purchases-payment-processing').length !== 0 && gatewayRedirectLinkEl) {
    gatewayRedirectLinkEl.onclick = openGatewayWindow
    openGatewayWindow()
  }

  function openGatewayWindow () {
    const redirectUrl = gatewayRedirectLinkEl.dataset.redirectUrl
    const gatewayWindow = window.open(redirectUrl, 'Payment Gateway')
    const currentWindowParams = Object.fromEntries(window.location.search.substr(1).split('&').map(pair => pair.split('=')))

    onWindowClose(gatewayWindow).then(() => window.location.replace(Routes.cart_summary_path(currentWindowParams)))
  }

  function onWindowClose (window) {
    return new Promise((resolve, reject) => {
      const timer = setInterval(() => {
        if (window.closed) {
          clearInterval(timer)
          resolve()
        }
      }, 500)
    })
  }
})
