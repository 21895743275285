import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["link", "collapsible"]

  connect () {
    let $linkTarget = $(this.linkTarget)
    let $collapsibleTarget = $(this.collapsibleTarget)

    $collapsibleTarget.collapse({ toggle: false })

    $linkTarget.click(function() {
      $collapsibleTarget.collapse('toggle')
    })
  }

  disconnect () {
    $(this.collapsibleTarget).collapse('dispose')
  }
}
