import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    $(this.element).select2({
      placeholder: this.placeholder,
      ajax: this.ajax,
      allowClear: this.allowClear,
      tags: this.tags,
      multiple: this.multiple,
      templateSelection: this.templateSelection.bind(this)
    })
  }

  disconnect () {
    $(this.element).select2('destroy')
  }

  get placeholder () {
    return this.element.dataset.placeholder || $.fn.select2.defaults.defaults.placeholder
  }

  get ajax () {
    const $this = this
    if (this.element.dataset.ajaxUrl) {
      return {
        url: this.element.dataset.ajaxUrl,
        dataType: this.element.dataset.ajaxDataType || 'json',
        delay: 250,
        data: params => Object.assign({}, params, JSON.parse(this.element.dataset.ajaxData || null)),
        cache: true,
        processResults: function (data, params) {
          if ($this.element.dataset.processResults) {
            data = data.results

            data.parentText = data.parentText || ''

            // Always return the object if there is nothing to compare
            if (params.term === undefined) {
              return {
                results: data
              }
            }
            const match = []

            if (data.length > 0) {
              data.map(object => {
                if (object.text.includes(params.term) && !match.includes(object)) {
                  match.push(object)
                } else {
                  object.children.map(children => {
                    if (children.text.includes(params.term)) {
                      if (match.length === 0) {
                        match.push({ text: object.text, children: [children] })
                      } else {
                        match.map(obj => {
                          if (match.map(hash => hash.text === object.text).includes(true)) {
                            if (obj.text.includes(object.text)) {
                              if (!obj.children.map(child => child === children).includes(true)) {
                                obj.children.push(children)
                              }
                            }
                          } else {
                            if (obj.text.includes(object.text)) {
                              if (!obj.children.map(child => child === children).includes(true)) {
                                obj.children.push(children)
                              }
                            } else {
                              match.push({ text: object.text, children: [children] })
                            }
                          }
                        })
                      }
                    }
                  })
                }
              })
            }
            return {
              results: match
            }
          } else {
            return {
              results: data.results
            }
          }
        }
      }
    } else {
      return null
    }
  }

  get allowClear () {
    return this.element.dataset.allowClear || $.fn.select2.defaults.defaults.allowClear
  }

  get tags () {
    return this.element.dataset.tags || $.fn.select2.defaults.defaults.tags
  }

  get multiple () {
    return this.element.dataset.multiple || $.fn.select2.defaults.defaults.multiple
  }

  templateSelection (data) {
    if (this.element.dataset.template && data.presenter) {
      return data.presenter
    } else {
      return data.text
    }
  }
}
