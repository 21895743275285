import { Controller } from '@hotwired/stimulus'
import Cookies from 'js-cookie'
import Pikaday from '../../../initializers/pikaday'

export default class extends Controller {
  static targets = ["calendar", "organizer"]

  connect () {
    const data = this.element.dataset
    this.elementId = $(this.element).attr('id');
    if (data.event_with_calendar === 'true') {
      const momentDates = Object.keys(JSON.parse(data.data)).map(date => moment(date))
      this.pikaday = new Pikaday({
        field: this.calendarTarget,
        container: this.calendarTarget,
        bound: false,
        defaultDate: moment(data.next_session_date).toDate(),
        setDefaultDate: true,
        minDate: new Date(),
        maxDate: moment(data.end_date).toDate(),
        i18n: this.getLanguage(),
        firstDay: 1, // Monday
        disableDayFn: dateToCheck => !momentDates.some(momentDate => momentDate.isSame(dateToCheck)),
        onSelect: this.updateOrganizer.bind(this),
        //onDraw: this.updateMonth.bind(this)
        //onChangeMonthYear: this.updateMonth.bind(this),
      })
      this.updateOrganizer(data.next_session_date)
    } 
  }

  disconnect () {
    this.pikaday.destroy()
  }

  updateOrganizer (ev) {
    const dateKey = moment(ev).format('YYYYMMDD')
    const locationId = this.element.dataset.location_id
    const elementCal = this.element;
    this.organizerTarget.querySelectorAll('.organizer_session__wrapper')
      .forEach(organizerDay => (organizerDay.style.display = 'none'))
    this.organizerTarget.querySelector('.organizer_day_' + dateKey).style.display = 'block'
    const dateElement = this.organizerTarget.querySelector(".organizer_day_" + dateKey)
    if (dateElement && dateElement.querySelector(".loading_ticket")){
      // Start loading spinner
      dateElement.querySelector(".loading_ticket").vivus?.play()
      document.dispatchEvent(new Event(`calendar_${dateKey}`))
    }
    document.dispatchEvent(new Event(`calendar_${locationId}_${dateKey}`))
    setTimeout(function() {
      if($(elementCal).find('.pika-next.is-disabled').length === 0){
        $('.pika-next').attr("data-action","click->components--events--calendar#nextMonth")
      }
      if($(elementCal).find('.pika-prev.is-disabled').length === 0){
        $('.pika-prev').attr("data-action","click->components--events--calendar#prevMonth")
      }
     }, 500);
  }

  nextMonth(ev) {
    $(this.element).hide()
    $("#spinner_"+this.elementId).show()
    this.pikaday.nextMonth()
    this.updateMonth()
  }

  prevMonth() {
    $(this.element).hide()
    $("#spinner_"+this.elementId).show()
    this.pikaday.prevMonth()
    this.updateMonth()
  }

  updateMonth(e) {
    if(typeof this.pikaday !== "undefined"){      
      $.ajax({
        url: `${this.element.dataset.slug}/show_async`,
        type: 'GET',
        data: { content: 'new_month_dates',
                location_id: this.element.dataset.location_id,
                month: this.pikaday.calendars[0].month,
                year:this.pikaday.calendars[0].year,
              },
        success: function (data) {
          $(this.element).replaceWith(data)
          $("#spinner_"+this.elementId).hide()
         }.bind(this)
      })
    }
  }

  getLanguage () {
    const locale = Cookies.get('locale') || 'pt'
    return {
      months: moment.localeData(locale)._months,
      weekdays: moment.localeData(locale)._weekdays,
      weekdaysShort: moment.localeData(locale)._weekdaysShort,
      previousMonth: '',
      nextMonth: ''
    }
  }
}
