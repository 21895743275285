import { Controller } from '@hotwired/stimulus'
import moment from 'moment'
import Pikaday from '../../../initializers/pikaday'

export default class extends Controller {
  connect () {
    this.create()
  }

  update () {
    this.picker.destroy()
    this.picker._o.trigger.value = ''
    // this.element.dataset.selectedDates = ''
    this.create()
  }

  create () {
    const data = this.element.dataset
    let selectedDates = this.selectedDates
    let momentDates = []
    if (data.dates !== '') {
      momentDates = JSON.parse(data.dates).map(date => moment(date))
    }
    if (this.element.dataset.allDates === 'true') {
      const dates = momentDates.map(date => moment(date).format('YYYY/MM/DD'))
      selectedDates = dates
      this.setSelectedDates(dates)
    }
    this.picker = new Pikaday({
      field: this.element,
      container: this.element,
      bound: false,
      disableDayFn: dateToCheck => !momentDates.some(momentDate => momentDate.isSame(dateToCheck)),
      defaultDate: selectedDates[0] ? moment(selectedDates[0]).toDate() : moment(momentDates[0]).toDate(),
      i18n: this.getLanguage(),
      firstDay: 1, // 1 == Monday
      onSelect: (date) => {
        // Format selected date
        const formattedDate = moment(date).format('YYYY/MM/DD')
        // Remove date if it was previous selected
        if (selectedDates.includes(formattedDate)) {
          selectedDates = selectedDates.filter((date) => date !== formattedDate)
        }
        // Otherwise add it to the selected dates
        else {
          selectedDates.push(formattedDate)
        }

        this.setSelectedDates(selectedDates)
      },
      selectDayFn: (date) => {
        // Check if a day is selected
        let dates = selectedDates

        if (moment(dates[0]).format('YYYY/MM/DD') != 'Invalid date') {
          dates = selectedDates.map(date => moment(date).format('YYYY/MM/DD'))
        }
        const formattedDate = moment(date).format('YYYY/MM/DD')
        return dates.includes(formattedDate)
      }
    })
  }

  get selectedDates () {
    if (this.element.dataset.selectedDates) {
      const dates = JSON.parse(this.element.dataset.selectedDates).map(date => moment(date).format('YYYY/MM/DD'))
      return dates
    } else {
      return []
    }
  }

  setSelectedDates (dates) {
    if (dates) {
      this.element.dataset.selectedDates = JSON.stringify(dates)
    }
  }

  getLanguage () {
    return {
      months: moment.localeData('pt')._months,
      weekdays: moment.localeData('pt')._weekdays,
      weekdaysShort: moment.localeData('pt')._weekdaysShort
    }
  }
}
