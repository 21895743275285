import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    $(this.element).railsSortable()
  }

  disconnect () {
    $(this.element).sortable('destroy')
  }
}
