import { Controller } from '@hotwired/stimulus'
import Swiper, { Navigation, Virtual, Pagination, Autoplay } from 'swiper'
import Plyr from 'plyr'
import sprite from './sprite.svg'

Swiper.use([Navigation, Virtual, Pagination, Autoplay])

export default class extends Controller {
  connect () {
    this.swiper = new Swiper(this.element, {
      slidesPerView: 1,
      loop: this.element.dataset.itemsCount > 1,
      navigation: {
        nextEl: '#swiper-next',
        prevEl: '#swiper-prev'
      },
      watchOverflow: this.element.dataset.itemsCount < 2,
      autoplay: {
        disableOnInteraction: false
      }
    })

    this.swiper.on('slideChange', this.onSlideChange.bind(this))

    this.players = []

    this.swiper.slides.forEach((slideEl, index) => {
      const slideContentEl = slideEl.firstElementChild

      if (slideContentEl.getAttribute('data-plyr-provider')) {
        const player = new Plyr(slideContentEl, {
          autoplay: false,
          muted: true,
          crossorigin: true,
          controls: ['play', 'mute', 'volume'],
          loadSprite: true,
          iconUrl: sprite
        })

        player.on('ready', () => {
          if (index === this.swiper.activeIndex) {
            player.play()
          }
        })

        this.players.push(player)
        slideEl.setAttribute('data-player-index', this.players.length - 1)
      }
    })
  }

  disconnect () {
    this.swiper.destroy()
  }

  onSlideChange () {
    const previousSlideEl = this.swiper.slides[this.swiper.previousIndex]
    const previousSlidePlayer = this.players[previousSlideEl.getAttribute('data-player-index')]
    if (previousSlidePlayer) previousSlidePlayer.pause()

    const currentSlideEl = this.swiper.slides[this.swiper.activeIndex]
    const currentSlidePlayer = this.players[currentSlideEl.getAttribute('data-player-index')]

    if (currentSlidePlayer) {
      currentSlidePlayer.restart()
      currentSlidePlayer.play()
    }
  }
}
