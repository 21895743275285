import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["totalPrice", "insuranceTotalPrice", "insuranceDiv", "commissionDiv", "commissionPrice", 
    "discountsDiv", "discountsTotalPrice", "promoDiv", "promoTotalPrice"]

  connect () {
    this.element["cartResume"] = this;
  }

  update(cart) {
    if(cart.insurance_total > 0){
      this.insuranceDivTarget.style.display = "flex";
      this.insuranceTotalPriceTarget.innerText = String(cart.insurance_total.toFixed(2)).replace(".", ",")
    } else {
      this.insuranceDivTarget.style.display = "none";
    }
    this.totalPriceTarget.innerText = String(cart.total.toFixed(2)).replace(".", ",")
  }

  update_commission(commissionType, commissionPrice, cartTotalPrice){
    this.commissionDivTarget.style.display = "flex";
    const percentage = 1
    if(commissionType == percentage){
      this.commissionPriceTarget.innerText = String(Number(commissionPrice)) + " %"
    } else {
      this.commissionPriceTarget.innerText = String(Number(commissionPrice).toFixed(2)).replace(".", ",") + " €"
    }
    this.totalPriceTarget.innerText = String(Number(cartTotalPrice).toFixed(2)).replace(".", ",")
  }

  update_discounts(cart){
    if(cart.discount_total > 0){
      this.discountsDivTarget.style.display = "flex";
      this.discountsTotalPriceTarget.innerText = "-" + String(cart.discount_total.toFixed(2)).replace(".", ",") + " €"
    } else {
      this.discountsDivTarget.style.display = "none";
    }
    this.totalPriceTarget.innerText = String(cart.total.toFixed(2)).replace(".", ",")
  }

  update_campaign(cart){
    if(cart.campaign_discount_total){
      this.promoDivTarget.style.display = "flex";
      this.promoTotalPriceTarget.innerText = String(cart.campaign_discount_total.toFixed(2)).replace(".", ",")
    } else {
      this.promoDivTarget.style.display = "none";
    }
    this.totalPriceTarget.innerText = String(cart.total.toFixed(2)).replace(".", ",")
  }
}
