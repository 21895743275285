import './calendar'
import './carts'
import './events'
import './general'
import './purchases'
import './venues'

$(document).on('turbo:load', () => {
  window.onmessage = ev => {
    if (ev.data.type === 'heightUpdateRequest') {
      const lastMainEl = document.querySelector('main').lastElementChild
      const height = lastMainEl.offsetTop + lastMainEl.clientHeight

      window.parent.postMessage({ type: 'heightUpdateResponse', height }, '*')
    }
  }
})
