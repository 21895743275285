import { Controller } from '@hotwired/stimulus'
import * as Routes from 'routes.js.erb'

export default class extends Controller {
  click (e) {
    const elements = []
    $("[selector='singleCheckbox']").map(function () {
      const ref = this.dataset.ref
      elements.push({ [ref]: true })
    })
    let data = {
      insurance: JSON.stringify(elements),
      pop_up_answer: true
    }
    if (e.currentTarget.dataset.widgetCode) {
      data = ({ ...data, widget_code: e.currentTarget.dataset.widgetCode })
    }

    window.addEventListener('beforeunload', () => {
      $('#insurance_spinner').show()
    })
    $.ajax({
      type: 'POST',
      url: Routes.cart_insurance_path(),
      data: data,
      success: function (response) {
        if (response.state.state === 'success') {
          location.reload()
        }
      }
    })
  }
}
