import Base from '../../../base'
import Renderer from '../../../../lib/dynamic_forms/renderer'
import Creator from '../../../../lib/dynamic_forms/creator'
import moment from 'moment'
class Form extends Base {
  setup () {
    const $this = this
    let calendar = false
    $('#dynamic_form_event_id').on('select2:selecting', e => {
      $this.clearAll('event')
      $('#dates_and_sessions').css({ display: 'none' })
      const selectedOption = e.params.args.data.id
      $('#dynamic_form_location_id').get(0).dataset.ajaxData = JSON.stringify({ event_id: selectedOption })
      $('#event_details').show()
      if (e.params.args.data.calendar === true) {
        calendar = true
        $('#dates_and_sessions').css({ display: 'grid' })
      } else {
        calendar = false
      }
    })
    $('#dynamic_form_location_id').on('select2:selecting', e => {
      $this.clearAll('location')
      const selectedEventOption = $('#dynamic_form_event_id').find(':selected').val()
      const selectedLocationOption = e.params.args.data.id
      if (selectedLocationOption == -1) {
        $('#dates_and_sessions').css({ display: 'none' })
      } else if (calendar === true) {
        $('#dates_and_sessions').css({ display: 'grid' })
        $.ajax({
          url: '/admin/select2/event_location_dates',
          type: 'GET',
          data: { event_id: selectedEventOption, location_id: selectedLocationOption },
          success: function (data) {
            $('#dynamic_form_dates_calendar').get(0).dataset.dates = JSON.stringify(data.results)
            $('#calendar_update').trigger('click')
          }
        })
      }
    })
    $('#dynamic_form_dates_calendar').on('change', function (ev) {
      $this.setSessions()
    })
    // select all dates
    $('#all_dates').on('click', () => {
      $('#dynamic_form_dates_calendar').get(0).dataset.allDates = $('#all_dates').is(':checked')
      if ($('#all_dates').checked === true) {
        $('#calendar_update').trigger('click')
        $this.setSessions()
      } else {
        $('#dynamic_form_dates_calendar').get(0).dataset.selectedDates = ''
        $('#calendar_update').trigger('click')
        $this.setSessions()
      }
    })
    // select all sessions
    $('#dynamic_form_all_sessions').on('click', () => {
      if ($('#dynamic_form_all_sessions').is(':checked') === true) {
        $('#dynamic_form_sessions').prop('disabled', true)
        $('#dynamic_form_sessions').val('').trigger('change')
      } else {
        $('#dynamic_form_sessions').prop('disabled', false)
      }
    })

    window.addEventListener('load', function () {
      const selectedEventOption = $('#dynamic_form_event_id').val()
      $('#dynamic_form_location_id').get(0).dataset.ajaxData = JSON.stringify({ event_id: selectedEventOption })
      if (selectedEventOption != undefined) {
        $('#event_details').show()
        $('#dates_and_sessions').css({ display: 'grid' })
      }
      const dates = $('#dynamic_form_dates_calendar').get(0).dataset.selectedDates
      $('#dynamic_form_dates').val(dates)
      const selectedDatesOption = dates

      const selectedLocationOption = $('#dynamic_form_location_id').val()
      $('#dynamic_form_sessions').get(0).dataset.ajaxData = JSON.stringify({ event_id: selectedEventOption, location_id: selectedLocationOption, dates: selectedDatesOption })

      $.ajax({
        url: '/admin/select2/event_location_dates',
        type: 'GET',
        data: { event_id: selectedEventOption, location_id: selectedLocationOption },
        success: function (data) {
          $('#dynamic_form_dates_calendar').get(0).dataset.dates = JSON.stringify(data.results)
          $('#calendar_update').trigger('click')
        }
      })
      if ($('#dynamic_form_all_sessions').is(':checked') === true) {
        $('#dynamic_form_sessions').prop('disabled', true)
        $('#dynamic_form_sessions').val('').trigger('change')
      } else {
        $('#dynamic_form_sessions').prop('disabled', false)
      }
    })

    const renderer = new Renderer('#preview', { previewMode: true })
    new Creator('#dynamic_form', renderer).setup()
  }

  setSessions () {
    const selectedEventOption = $('#dynamic_form_event_id').find(':selected').val()
    const selectedLocationOption = $('#dynamic_form_location_id').find(':selected').val()
    const dates = $('#dynamic_form_dates_calendar').get(0).dataset.selectedDates
    $('#dynamic_form_dates').val(dates)
    const selectedDatesOption = dates
    $('#dynamic_form_sessions').get(0).dataset.ajaxData = JSON.stringify({ event_id: selectedEventOption, location_id: selectedLocationOption, dates: selectedDatesOption })
  }

  clearAll (e) {
    if (e === 'event') {
      $('#dynamic_form_location_id').empty().trigger('change')
    }
    $('#dynamic_form_dates').val('')
    $('#dynamic_form_dates_calendar').get(0).dataset.dates = ''
    $('#calendar_update').trigger('click')
    $('#dynamic_form_sessions').empty().trigger('change')
  }
}

export default Form
