import { Controller } from '@hotwired/stimulus'
import i18n from "../../../initializers/i18n";

export default class extends Controller {
    connect() {
        const blankMessage = this.IDsToBlankMessage[this.element.id] ?? 'errors.messages.blank';
        this.errorMessage = this.element.getElementsByClassName('invalid-feedback')[0]
        this.inputField = this.element.id === 'deliveryCountry' ?
            $('#purchases_data_form_delivery_country_id') :
            $('#purchases_data_form_delivery_district_id')
        this.inputField.on('invalid', (event) => {
            event.preventDefault();
            this.element.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center',
                top: '-32px',
            })
            this.inputField.addClass(" is-invalid")
            this.errorMessage.style.display = 'block';
            this.errorMessage.innerHTML = i18n.t(blankMessage)
            if(!this.phoneDelivery) this.phoneDelivery =
                document.getElementById('phone_delivery')['components--cart--international-phone'].intl;
            if(!this.phoneBill) this.phoneBill =
                document.getElementById('phone_bill')['components--cart--international-phone'].intl;
            this.phoneDelivery.setNumber(this.phoneDelivery.getNumber())
            this.phoneBill.setNumber(this.phoneBill.getNumber())
        })

        this.inputField.on('input', () => {
            this.errorMessage.style.display = 'none';
            this.inputField.removeClass(" is-invalid")
        })
        this.inputField.on('change', () => {
            this.errorMessage.style.display = 'none';
            this.inputField.removeClass(" is-invalid")
        })
    }

    IDsToBlankMessage = {
        ['deliveryCountry']: 'errors.messages.blankCountry',
        ['deliveryDistrict']: 'errors.messages.blankDistrict',
    }
}