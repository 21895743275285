import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["icone", "textCopy", "textCopied"]
  static values = {
    url: String
  }

  connect(){
    this.textCopiedTarget.style.display = "none";
  }

  click(){
    navigator.clipboard
      .writeText(this.urlValue)
      .then(() => {
        this.iconeTarget.classList.add("copied__icon")
        this.textCopiedTarget.style.display = "block";
        this.textCopiedTarget.classList.add("copied__text")
        this.textCopyTarget.style.display = "none";

        setTimeout(function(){
          this.iconeTarget.classList.remove("copied__icon")
          this.textCopiedTarget.style.display = "none";
          this.textCopiedTarget.classList.remove("copied__text")
          this.textCopyTarget.style.display = "block";
        }.bind(this), 4000);
      })
  }

}
