import Base from '../../../base'
class SummaryTable extends Base {
  setup () {
    $("p[selector='toggler']").on('click', function (e) {
      const toggle = e.target.closest('p')
      if ($(toggle).find('i').hasClass('fa-chevron-down')) {
        $(toggle).closest("div[selector='summary_table']").find("div[selector='tickets']").css('display', 'block')
        $(toggle).find('.hide').css('display', 'block')
        $(toggle).find('.show').css('display', 'none')
        $(toggle).find('i').removeClass('fa-chevron-down')
        $(toggle).find('i').addClass('fa-chevron-up')
      } else {
        $(toggle).closest("div[selector='summary_table']").find("div[selector='tickets']").css('display', 'none')
        $(toggle).find('.hide').css('display', 'none')
        $(toggle).find('.show').css('display', 'block')
        $(toggle).find('i').removeClass('fa-chevron-up')
        $(toggle).find('i').addClass('fa-chevron-down')
      }
    })
  }
}

export default SummaryTable
