import Swiper, { Navigation, Virtual, Pagination } from 'swiper'
import Cookies from 'js-cookie'

$(document).on('turbo:load', () => {
  if ($('#general-index').length !== 0) {
    $('.scroll-down').on('click', function () {
      $('html, body').animate({
        scrollTop: $('#homepage_scroll').offset().top - 40
      }, 1500)
    })

    $('#district_id').on('change', function (e) {
      Cookies.set('nearby_events', false)
      const $spinner = $('#spinner')
      const $nearbyEvents = $('#nearby_events')
      $nearbyEvents.css('display', 'none')
      $spinner.fadeIn()

      const interval = setInterval(function () {
        const nearbyEvents = Cookies.get('nearby_events')
        if (nearbyEvents == 'true') {
          $spinner.css('display', 'none')
          $nearbyEvents.fadeIn()
          clearInterval(interval)
        }
      }, 100)
    })
  }

  const navbarCart = $('.nav_header .cart_icon__timer');
  if(navbarCart.length !== 0) {
    const timestamp = Date.parse(navbarCart.data('expires-at'));
    if (timestamp) {
      const timer = setInterval(function() {
        const currentTime = new Date().getTime();
        const remainingTime = timestamp - currentTime;
        if (remainingTime <= 0) {
          clearInterval(timer);
          $('.cart_icon__counter').remove();
          $('.cart-expired-overlay').removeClass('none');
          navbarCart.text('');
        } else {
          const remainingSeconds = Math.floor(remainingTime / 1000);
          const minutes = Math.floor(remainingSeconds / 60).toString().padStart(2, '0');
          const seconds = (remainingSeconds % 60).toString().padStart(2, '0');
          navbarCart.text(minutes + ':' + seconds);
        }
      }, 1000);
    }
  }

  document.addEventListener('render_async_load', function (event) {
    if (event.container.get(0).id == '1') {
      setupSelects()
    }
  })
})
