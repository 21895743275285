$(document).on('turbo:load', () => {
  if ($('#submenuContainer').length !== 0) {
    const $accordionHeading = $('.sidebar .sidebar_accordion .accordion_heading')
    const $sidebar = $('.sidebar')
    const $sidebarList = $('.sidebar .sidebar_accordion ul')

    $('ul .sidebar_accordion:has(ul .sidebar__active)').addClass('sidebar_accordion__active')
    $sidebar.hover(function () {}, function () {
      $accordionHeading.addClass('collapsed')
      $accordionHeading.attr('aria-expanded', 'false')
      $sidebarList.removeClass('show')
    })
  }
})
