import { Controller } from '@hotwired/stimulus'
import Cookies from 'js-cookie'

export default class extends Controller {
  connect () {
    const cookiesAccepted = Cookies.get('cookies_accepted')
    if (cookiesAccepted) {
      $(this.element).remove()
    } else {
      $(this.element).css('display', 'flex')
    }
  }

  accept () {
    // NOTE: We need to remove it instead of hiding because of issues on the widget (the height does not reset)
    $(this.element).remove()
  }
}
