import { Controller } from '@hotwired/stimulus'
import Vivus from 'vivus'

export default class extends Controller {
  connect () {
    const loadingDiv = document.getElementsByClassName('loading_ticket')
    Array.from(loadingDiv).forEach(element => {
      const startDisabled = element.parentNode.getAttribute('data-start-disabled') === 'true'
      const vivus = new Vivus(
        element,
        {
          duration: 120,
          start: (startDisabled ? 'manual' : 'autostart'),
          animTimingFunction: Vivus.EASE
        },
        function (vivus) {
          if (vivus.getStatus() === 'end' && element.getAttribute('disabled') !== 'true') {
            vivus.reset().play()
          }
        }
      )
      element.vivus = vivus
    })
  }
}
