export default function setupSelects () {
  // Iterate over each select element
  $('.select').each(function () {
    var $this = $(this)

    if ($this.parent().hasClass('select_container')) return

    // Cache the number of options
    var numberOfOptions = $(this).children('option').length

    // Hides the select element
    $this.addClass('s-hidden')

    // Wrap the select element in a div
    $this.wrap('<div class="select_container"></div>')

    // Insert a styled div to sit over the top of the hidden select element
    $this.after('<div class="styled_select"></div>')

    // Cache the styled div
    var $styledSelect = $this.next('div.styled_select')

    // Show the first select option in the styled div
    $styledSelect.text($this.children('option:selected').text())

    // Insert an unordered list after the styled div and also cache the list
    let $list = null

    if (
      $this.parent().parent().hasClass('select--manager') ||
        $this.parent().parent().parent().hasClass('select--manager')
    ) {
      $list = $('<ul />', { class: 'options options--manager' }).insertAfter(
        $styledSelect
      )
    } else {
      $list = $('<ul />', { class: 'options' }).insertAfter($styledSelect)
    }

    // Insert a list item into the unordered list for each select option
    for (var i = 0; i < numberOfOptions; i++) {
      $('<li />', {
        text: $this.children('option').eq(i).text(),
        rel: $this.children('option').eq(i).val()
      }).appendTo($list)
    }

    // Cache the list items
    var $listItems = $list.children('li')

    $styledSelect.click(function (e) {
      e.stopPropagation()
      if ($(this).hasClass('active')) {
        $(this).removeClass('active').next('ul.options').hide()
      } else {
        $('div.styled_select').removeClass('active').next('ul.options').hide()
        $(this).addClass('active').next('ul.options').show()
      }
    })

    // Hides the unordered list when a list item is clicked and updates the styled div to show the selected list item
    // Updates the select element to have the value of the equivalent option
    $listItems.click(function (e) {
      e.stopPropagation()
      $styledSelect.text($(this).text()).removeClass('active')
      $this.val($(this).attr('rel')).trigger('change')
      $list.hide()
      /* alert($this.val()); Uncomment this for demonstration! */
    })

    // Check if text is too big
    var maxLength = 14

    if ($styledSelect.prevObject.context.className.includes('stripped-text')) {
      $styledSelect.text(function (i, text) {
        if (text.length > maxLength) {
          return text.substr(0, maxLength) + '...'
        }
      })
    }

    // Hides the unordered list when clicking outside of it
    $(document).click(function () {
      $styledSelect.removeClass('active')
      $list.hide()
    })
  })
}
