import { Controller } from '@hotwired/stimulus'
import moment from 'moment'

export default class extends Controller {
  connect () {
    $(this.element).daterangepicker({
      locale: this.locale(),
      autoApply: true,
      minDate: this.minDate,
      startDate: this.startDate,
      endDate: this.endDate
    })
  }

  get minDate () {
    const minDate = this.element.dataset.minDate
    return minDate ? new Date(minDate) : false
  }

  get startDate () {
    const startDate = this.element.dataset.startDate
    return startDate ? moment(startDate, 'YYYY-MM-DD') : moment()
  }

  get endDate () {
    const endDate = this.element.dataset.endDate
    return endDate ? moment(endDate, 'YYYY-MM-DD') : moment()
  }

  locale () {
    const locale = $('#locale').val() || 'pt'
    return {
      format: this.element.dataset.format || 'YYYY-MM-DD',
      monthNames: moment.localeData(locale)._months,
      daysOfWeek: moment.localeData(locale)._weekdaysShort
    }
  }
}
