import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    totalPrice: Number,
    totalTickets: Number,
  }

  static targets = ["totalPrice", "totalTickets", "confirmButton", "sessionMaxTickets"]

  initialize(){
    this.totalPriceValue = 0
    this.totalTicketsValue = 0
    this.block = false
  }

  // ACTIONS
  add(event){
    if (this.block) {
      return
    }
    const $parent = $(event.currentTarget).parent()
    const $input = $parent.find("[selector='price']")
    const $counter = $parent.find("[selector='counter']")
    const $decrement = $parent.find("[selector='decrement']")
    const $sessionMaxTickets = this.sessionMaxTicketsTarget.value ? this.sessionMaxTicketsTarget.value : null
    const $typologyMaxTickets = $parent.find(".typology_max_tickets").val() ? $parent.find(".typology_max_tickets").val() : null
    const newValue = parseInt($input.val()) + 1
    const availability = $parent.find("[selector='availability']").get(0).dataset.available
    
    if (availability === 'true' && this.block === false){
      if ($sessionMaxTickets || $typologyMaxTickets){
        if (this.totalTicketsValue + 1 == $sessionMaxTickets){
          this.block = true
          $(document).find(".increment").removeClass('bg-dark-gray').addClass('bg-light-gray')
          $(document).find('.tooltip').attr('data-active', '')
        } else if (newValue == $typologyMaxTickets) {
          this.block = true
          $parent.find('.tooltip').attr('data-active', '')
          $parent.find(".increment").removeClass('bg-dark-gray').addClass('bg-light-gray')
        } else if($typologyMaxTickets != null && newValue > $typologyMaxTickets){
          return
        }
      }
    } else {
      return
    }
    $input.val(newValue)
    $counter.text(newValue)
    this.totalTicketsValue += 1
    this.totalPriceValue += parseFloat($input.data('price'))
    $decrement.removeClass('bg-light-gray').addClass('bg-dark-gray')
    
    this.updateTotal()
  }

  remove(event){
    const $parent = $(event.currentTarget).parent()
    const $input = $parent.find("[selector='price']")
    const $counter = $parent.find("[selector='counter']")
    const $decrement = $parent.find("[selector='decrement']")

    let newValue = parseInt($input.val()) - 1
    if (newValue < 0 ){
      newValue = 0
    } else {
      this.totalTicketsValue -= 1
      this.totalPriceValue -= parseFloat($input.data('price'))
      this.updateTotal()
    }

    if(newValue == 0){
      $decrement.removeClass('bg-dark-gray').addClass('bg-light-gray')
    }

    if (this.block) {
      this.block = false
      const availability = $(document).find("[selector='availability']")
      availability.each(function () {
        if (this.dataset.available === 'true') {
          $(this).parent().find('.increment').removeClass('bg-light-gray').addClass('bg-dark-gray')
          $(this).parent().find('.tooltip').attr('data-active', 'false')
        }
      })
    }
    $input.val(newValue)
    $counter.text(newValue)
  }

  updateTotal() {

    const $button = $(this.confirmButtonTarget)
    this.totalTicketsTarget.innerText = this.totalTicketsValue
    this.totalPriceTarget.innerText = this.totalPriceValue.toFixed(2).replace('.', ',')

    if (this.totalTicketsTarget.innerText > 0) {
      $button.prop('disabled', false)
    } else {
      $button.prop('disabled', true)
    }
  }
}