import { Controller } from '@hotwired/stimulus'
import i18n from "../../../initializers/i18n";

export default class extends Controller {
    connect() {
        const blankMessage = this.IDsToBlankMessage[this.element.id] ?? 'errors.messages.blank'
        this.errorMessage = this.element.getElementsByClassName('invalid-feedback')[0]
        this.inputField = this.element.getElementsByClassName('user_input tl_input tl_input--dark form-input')[0]
            ?? document.getElementById('purchases_data_form_delivery_country_id')
            ?? document.getElementById('purchases_data_form_delivery_district_id')
        this.inputField.addEventListener('invalid', (event) => {
            event.preventDefault();
            this.element.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center',
                top: '-32px',
            })
            this.inputField.classList.add("is-invalid")
            this.errorMessage.style.display = 'block';
            this.errorMessage.innerHTML = this.inputField.value ? i18n.t('errors.messages.invalid_email') : i18n.t(blankMessage)
            if(!this.phoneDelivery) this.phoneDelivery =
                document.getElementById('phone_delivery')['components--cart--international-phone'].intl;
            if(!this.phoneBill) this.phoneBill =
                document.getElementById('phone_bill')['components--cart--international-phone'].intl;
            this.phoneDelivery.setNumber(this.phoneDelivery.getNumber())
            this.phoneBill.setNumber(this.phoneBill.getNumber())
        })
        this.inputField.addEventListener('input', () => {
            this.errorMessage.style.display = 'none';
            this.inputField.classList.remove("is-invalid")
        })
        this.inputField.addEventListener('change', () => {
            this.errorMessage.style.display = 'none';
            this.inputField.classList.remove("is-invalid")
        })
    }

    IDsToBlankMessage = {
        ['deliveryName']: 'errors.messages.blankName',
        ['deliveryEmail']: 'errors.messages.blankEmail',
        ['deliveryPostalCode']: 'errors.messages.blankPostalCode',
        ['deliveryAddress']: 'errors.messages.blankLocality',
        ['deliveryCity']: 'errors.messages.blankLocality',
    }
}