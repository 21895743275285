import { Controller } from '@hotwired/stimulus'
import 'intl-tel-input/build/css/intlTelInput.css'
import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/js/utils.js'
import i18n from '../../../initializers/i18n'
export default class extends Controller {
  static targets = ["phoneNumber"]

  connect () {
    this.element[this.identifier] = this;
    const submitButton = document.getElementById('submit_data')
    const errorMap = [i18n.t("errors.messages.invalid_phone"), i18n.t("errors.messages.invalid_country_code"), 
      i18n.t("errors.messages.phone_too_short"), i18n.t("errors.messages.phone_too_long"), 
      i18n.t("errors.messages.invalid_phone")]
    
    const input = this.phoneNumberTarget
    const int = intlTelInput(input, {
      nationalMode: true,
      autoHideDialCode: false,
      preferredCountries: ['pt', 'gb', 'es'],
      separateDialCode: true,
      setNumber: true,
      formatOnDisplay: true,
      utilsScript: 'intl-tel-input/build/js/utils.js'
    })
    this.intl = int;
    this.dialCode = "+" + int.getSelectedCountryData()['dialCode'];
    const phoneCountryField = $("#"+ input.id + "_country")
    if (phoneCountryField.val()) {
      int.setCountry(phoneCountryField.val())
    } else {
      phoneCountryField.val(int.getSelectedCountryData()['iso2'])
    }

    const maskingPortugal = () => {
      const fullNumber = int.getNumber();
      const simplifiedNumber = fullNumber.replaceAll(this.dialCode, "").replace(/\s/g, "");
      if((simplifiedNumber.length%3) !== 0) {
        int.setNumber(simplifiedNumber.replace(/(.{3})/g, "$1 "));
      }
    }

    const maskingInternational = () => {
      const fullNumber = int.getNumber();
      const simplifiedNumber = fullNumber.replaceAll(this.dialCode, "")
      int.setNumber(simplifiedNumber);
    }

    input.addEventListener('focus', () => {
      $(input).removeClass(" is-invalid")
      document.querySelector("#error_"+ input.id +"_country").innerHTML = ""
    })

    input.addEventListener('input', (int.getSelectedCountryData()['iso2'] === "pt") ? maskingPortugal : maskingInternational)

    input.addEventListener("countrychange", function() {
      phoneCountryField.val(int.getSelectedCountryData()['iso2'])
      this.dialCode = "+" + int.getSelectedCountryData()['dialCode'];
      if (int.getSelectedCountryData()['iso2'] === "pt"){
        input.removeEventListener('input', maskingInternational)
        input.addEventListener('input', maskingPortugal)
      } else {
        input.removeEventListener('input', maskingPortugal)
        input.addEventListener('input', maskingInternational)
      }
    })

    input.addEventListener('blur', (e) => {
      document.querySelector("#error_"+ input.id +"_country").innerHTML = ""
      errorHandling(e)
    })

    $(this.element).find('.invalid-feedback').css("display", "block")
    submitButton.addEventListener("click", function(e) {
      document.querySelector("#error_"+ input.id +"_country").innerHTML = ""
      const evaluatedError = errorHandling(e)
      if(evaluatedError === 0){
        $(input).val(int.getNumber())
      } else if (evaluatedError === 1) {
        e.preventDefault();
        input.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center',
          top: '-32px',
        })
      }
    })

    const errorHandling = (e) => {
      document.querySelector("#error_"+ input.id +"_country").innerHTML = ""
      if (input.id === 'purchases_data_form_delivery_phone' || input.id === 'purchases_data_form_billing_phone' && input.value) {
        if (int.isValidNumber()){
          return 0;
        } else {
          const errorCode = int.getValidationError()
          document.querySelector("#error_"+ input.id +"_country").innerHTML = errorMap[errorCode] ? errorMap[errorCode] : i18n.t("errors.messages.blankPhone")
          $(input).addClass(" is-invalid")
          return 1;
        }
      }
    }
  }
}
