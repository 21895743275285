import { Controller } from '@hotwired/stimulus'
import Swiper, { Navigation } from 'swiper'
import 'swiper/swiper-bundle.min.css'

Swiper.use([Navigation])

export default class extends Controller {
  connect () {
    this.swiper = new Swiper(this.element, {
      slidesPerView: 1.1,
      slidesPerColumn: this.perColumn,
      slidesPerGroup: 1,
      spaceBetween: 8,
      slidesPerColumnFill: 'row',
      speed: 1500,
      navigation: {
        nextEl: '.fa-chevron-right',
        prevEl: '.fa-chevron-left'
      },
      breakpoints: {
        760: {
          spaceBetween: 16,
          slidesPerView: 2,
          slidesPerGroup: 2
        },
        1140: {
          slidesPerView: this.perRow,
          slidesPerGroup: this.perRow
        }
      }
    })
  }

  disconnect () {
    this.swiper.destroy()
  }

  get perColumn () {
    if (this.element.dataset.events && this.element.dataset.events <= 8) {
      return 1
    } else {
      return this.element.dataset.per_column || 1
    }
  }

  get perRow () {
    return this.element.dataset.per_row || 3
  }
}
