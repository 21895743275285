import Base from '../../../base'
class Form extends Base {
  setup () {
    tinymce.init({
      selector: '.tinymce',
      height: 500,
      menubar: false,
      branding: false,
      language: 'pt_PT',
      plugins: [
        'autolink lists link image charmap preview',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount'
      ],
      table_default_attributes: {
        border: '0'
      },
      invalid_styles: {
        table: 'width height border-collapse',
        tr: 'width height',
        th: 'width height',
        td: 'width height'
      },
      toolbar: 'undo redo | bold italic underline strikethrough | formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | removeformat table | charmap | fullscreen  preview | insertfile image media template link | ltr rtl'
    }).then(() => {
      $('.spinner').css('display', 'none')
    })
  }
}

export default Form
