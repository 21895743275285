$(document).on('turbo:load', () => {
  if ($('#calendar-index').length !== 0) {
    $('#start_end_date_range').on('apply.daterangepicker', (ev, picker) => {
      const startDate = picker.startDate.format()
      const endDate = picker.endDate.format()
      $('#start_date').val(startDate)
      $('#end_date').val(endDate)
    })
  }
})
