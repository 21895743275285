$(document).on('turbo:load', () => {
  if ($('#admin-messages-new').length !== 0) {
    const $eventSearch = $('[selector="event_search"]')
    const $eventsSelect2 = $('[selector="events_select2"]')
    const $eventSearchButton = $('[selector="event_search_button"]')
    const $generalSearchButton = $('[selector="general_search_button"]')
    const $emailsSelect2 = $('[selector="emails_select2"]')

    $eventSearch.hide()
    $eventSearchButton.get(0).onclick = () => {
      $eventSearch.show()
      $generalSearchButton.get(0).classList.remove('btn-search-message--active')
      $eventSearchButton.get(0).classList.add('btn-search-message--active')
    }

    $generalSearchButton.get(0).onclick = () => {
      $eventsSelect2.val(null).trigger('change')
      $generalSearchButton.get(0).classList.add('btn-search-message--active')
      $eventSearchButton.get(0).classList.remove('btn-search-message--active')
      $eventSearch.hide()
    }

    $eventsSelect2.on('select2:selecting', e => {
      const selectedOption = e.params.args.data.id
      $emailsSelect2.get(0).dataset.ajaxData = JSON.stringify({ event_id: selectedOption })
    })

    $emailsSelect2.on('select2:selecting', e => {
      const selectedOption = e.params.args.data

      if (selectedOption.text === 'Todos') {
        this.$emailsSelect2.val(['Todos']).trigger('change')
      } else {
        const selectedOptions = $emailsSelect2.val()
        if (selectedOptions && selectedOptions[0] === 'Todos') $emailsSelect2.val([])
      }
    })
  }
})
