import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["triggerWrapper", "modal"]

  connect () {
    this.confirmed = false
    this.triggerTarget = this.triggerWrapperTarget.firstElementChild
    this.triggerTarget.onclick = this.onClick.bind(this)
  }

  onClick (event) {
    if (this.enabled && !this.confirmed) {
      this.show()
      event.stopPropagation()
      event.preventDefault()
    }
  }

  show () {
    if (this.enabled) this.modalTarget.style.display = 'block'
  }

  hide (event) {
    this.modalTarget.style.display = 'none'
    event.preventDefault()
  }

  confirm () {
    this.confirmed = true
    this.triggerTarget.click()
  }

  get enabled () {
    return this.element.dataset.enabled === 'true'
  }
}
