import './swiper'
import './moment'
import './select'
import '@fortawesome/fontawesome-pro/css/all.css'

// pikaday
import './pikaday'
import 'pikaday/css/pikaday.css'

import './application_insights.js.erb'
