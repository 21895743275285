import { Renderer } from '../../../../lib/dynamic_forms'
import SummaryTable from '../partials/summary_table'

$(document).on('turbo:load', () => {
  new SummaryTable().setup()
  const deliveryCountrySel =  $('#purchases_data_form_delivery_country_id');
  const deliveryDistrictSel = $('#purchases_data_form_delivery_district_id');
  const billingCountrySel =  $('#purchases_data_form_billing_country_id');
  const billingDistrictSel = $('#purchases_data_form_billing_district_id');
  const dropdownBilling = $('#dropdown_billing_data');
  const billingSection = $('#billing-data__section')
  billingSection.slideToggle(0);
  if ($('#purchases-data, #purchases-data-processing').length !== 0) {
    $('[selector="dynamic_form"]').each((_index, $dynamicForm) => (new Renderer($dynamicForm)).render())

    const $countrySelects = $('#purchases_data_form_delivery_country_id, #purchases_data_form_billing_country_id')
    $countrySelects.on('change', ev => {
      const districtSelectEl = document.getElementById(ev.currentTarget.id.replace(/country/, 'district'))
      const districtSelectWrapperElement = districtSelectEl.parentElement
      if (ev.target.selectedOptions.length > 0 && ev.target.selectedOptions[0].text === 'Portugal') {
        districtSelectWrapperElement.style.display = 'block'
        districtSelectEl.require = true;
      } else {
        districtSelectEl.require = false;
        districtSelectWrapperElement.style.display = 'none'
        districtSelectEl.value = -1
        districtSelectEl.dispatchEvent(new Event('change'))
      }
    })
    $countrySelects.trigger('change')

    for (const element of document.getElementsByClassName("invalid-feedback")){
      if(element.outerText && element.id !== 'aside_error') {
        element.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center',
          top: '-32px',
        })
        break;
      }
    }

    $('#copy_delivery_data').on('click', () => {
      const deliveryFields = ['name', 'address', 'phone', 'postal_code', 'nif', 'email', 'city']
      const phoneDelivery = document.getElementById('phone_delivery')['components--cart--international-phone'].intl;
      const phoneBill = document.getElementById('phone_bill')['components--cart--international-phone'].intl;
      deliveryFields.forEach(field => {
        if(field !== 'phone') {
          const deliveryValue = $('#purchases_data_form_delivery_' + field).val()
          $('#purchases_data_form_billing_' + field).val(deliveryValue)
        } else {
          phoneBill.setCountry(phoneDelivery.getSelectedCountryData()['iso2'])
          phoneBill.setNumber(phoneDelivery.getNumber())
        }
        const errorMessage = $('#error-msg-purchases_data_form_billing_' + field)

        if (errorMessage !== '') {
          errorMessage.text('')
          $('#purchases_data_form_billing_' + field).removeClass('is-invalid')
        }
      })

      const selectDeliveryFields = ['district_id', 'country_id']
      selectDeliveryFields.forEach(field => {
        const deliveryValue = $('#purchases_data_form_delivery_' + field).val()
        $('#purchases_data_form_billing_' + field).val(deliveryValue).trigger('change')

        const errorMessage = $('#error-msg-purchases_data_form_billing_' + field)
        if (errorMessage !== '') {
          errorMessage.text('')
          $('#purchases_data_form_billing_' + field).removeClass('is-invalid')
        }
      })
      dropdownBilling.addClass('down')
      billingSection.slideDown(200);
    })

    dropdownBilling.click(() => {
      dropdownBilling.toggleClass('down')
      billingSection.slideToggle(200);
    }).css("cursor", "pointer");

    $('input[type=checkbox]').each(function () {
      const dynamicForm = $("[selector='dynamic_form']")
      if (dynamicForm.get(0)) {
        if (dynamicForm.get(0).dataset.data) {
          for (const [key, value] of Object.entries(JSON.parse($("[selector='dynamic_form']").get(0).dataset.data))) {
            if (this.name.includes(key) && value === 'true') {
              this.checked = true
            }
          }
        }
      }
    })

    $('#checkout_steps_link').on('click', () => {
      $('#new_purchases_data_form').submit()
    })

    deliveryCountrySel.on('change', () => {
      deliveryCountrySel.select2("close")
      if(deliveryCountrySel.val() === '1'){
        deliveryDistrictSel.attr('required', true)
      } else {
        deliveryDistrictSel.attr('required', false)
      }
    })
    deliveryDistrictSel.on('change', () => {
      deliveryDistrictSel.select2("close")
    })
    billingCountrySel.on('change', () => {
      billingCountrySel.select2("close")
    })
    billingDistrictSel.on('change', () => {
      billingDistrictSel.select2("close")
    })

    window.addEventListener('beforeunload', () => {
      $('#full_spinner').show()
    })

    $('.sidebar-checkout__container').find('.invalid-feedback').css('display', 'block')
  }
})
